@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-white;
    scroll-behavior: smooth;
  }
}

::-webkit-scrollbar {
  width: 0px;
}
